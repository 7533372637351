<script>
export default {
  name: 'Subtitle',
  props: {
    productId: {
      type: Number,
    }
  }
}
</script>

<template>
  <div class="subtitle">
    <div v-if="productId === 26182">Интернет магазин</div>
  </div>
</template>

<style scoped>
.subtitle > * {
  color: #6E7882;
  font-size: 13px;
}
</style>
